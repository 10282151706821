<template>
  <div>
    <v-row>
      <v-icon v-if="prependIcon" class="mr-2">{{ prependIcon }}</v-icon>

      <span v-if="errorMessages" class="error--text">{{ errorMessages }}</span>

      <!-- <template v-else> -->
      <v-tooltip
        bottom
        v-else-if="
          !registeredText && registered && (!Files || Files.length < 1)
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            class="ma-1"
            color="pink"
            outlined
            dark
            small
            >{{ registered }}</v-chip
          >
        </template>
      </v-tooltip>

      <v-chip
        v-for="(file, i) in Files"
        :key="i"
        class="ma-1"
        :color="chipColor"
        :dark="dark"
        close
        small
        @click:close="multiple ? Files.splice(i, 1) : (Files = null)"
        >{{ getFileName(file.name) }} ({{ getFileSize(file.size) }})
      </v-chip>
      <!-- </template> -->
      <v-spacer />
      <v-btn :color="color" :dark="dark" @click="click">
        <v-icon v-if="appendIcon" left>{{ appendIcon }}</v-icon
        >{{ text }}</v-btn
      >
    </v-row>
    <input
      ref="input"
      type="file"
      style="display: none"
      :accept="accept"
      :multiple="multiple"
      show-size
      @change="change"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      files: null
    };
  },
  props: {
    value: { type: [Array, File], default: null },
    registered: String,
    multiple: Boolean,
    appendIcon: { type: String, default: "mdi-file-upload" },
    prependIcon: String,
    text: { type: String, default: "アップロード" },
    color: { type: String, default: "primary" },
    chipColor: { type: String, default: "secondary" },
    dark: Boolean,
    accept: String,
    registeredText: { type: Boolean, default: false },
    errorMessages: String
  },
  computed: {
    Files: {
      get() {
        if (this.value) {
          if (this.files) this.clear();
          return this.multiple ? this.value : [this.value];
        } else {
          return this.files;
        }
      },
      set(value) {
        this.$emit("input", !value ? null : this.multiple ? value : value[0]);
        if (!this.value) this.files = value;
      }
    }
  },
  methods: {
    click() {
      this.$refs.input.click();
      this.$emit("click");
    },
    change() {
      const files = [];
      for (let i = 0; i < this.$refs.input.files.length; i++)
        files.push(this.$refs.input.files[i]);
      this.Files = files;
      this.$emit("change", this.multiple ? files : files[0]);
      this.$refs.input.value = null;
    },
    clear() {
      this.files = null;
    },
    getFileName(filename) {
      return filename.length < 20
        ? filename
        : filename.substr(0, 10) + "..." + filename.substr(filename.length - 6);
    },
    getFileSize(bytesize) {
      const kb = 1024,
        mb = kb * kb,
        gb = mb * kb;

      if (bytesize < kb) {
        return bytesize + " B";
      } else if (bytesize < mb) {
        return Math.round(bytesize / kb) + " kB";
      } else if (bytesize < gb) {
        return Math.round(bytesize / mb) + " mB";
      } else {
        return Math.round(bytesize / gb) + " gB";
      }
    }
  }
};
</script>
